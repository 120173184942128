/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;

$theme: mat.define-theme((
    color: (
        theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$azure-palette
    ),
));

:root {
    mat-toolbar {
        --mat-toolbar-container-background-color: #{mat.get-theme-color($theme, primary, 70)};
    }
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mdc-notched-outline__notch
{
    border-right: none;
}
